<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <div v-else>
    <div :hidden="true">
      {{ updateKey }}
    </div>
    <div class="mb-3">
      <InputText
        id="invitationEmail"
        v-model="values.email"
        type="text"
        :class="['mr-1', !!errors.email && 'p-invalid']"
        placeholder="Email to invite..."
        @blur="validate('email')"
        @keypress="validate('email')"
      />
      <!--       <small v-if="!!errors.email">
        {{ errors.email }}
      </small> -->
      <Button
        label="Send invitation"
        :disabled="sendingEmail"
        @click="sendInvitationHandler"
      />
    </div>
    <DataTable
      v-model:filters="filters"
      :value="invitations"
      responsive-layout="scroll"
      :scrollable="true"
      scroll-height="800px"
      scroll-direction="both"
      :global-filter-fields="['email']"
      context-menu
      :rows="20"
      paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rows-per-page-options="[10, 20, 50]"
      :paginator="true"
      current-page-report-template="Showing {first} to {last} of {totalRecords}"
    >
      <template #empty>
        No data found.
      </template>

      <template #header>
        <div class="flex justify-content-between">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              placeholder="Search by email..."
            />
          </span>
        </div>
      </template>

      <Column
        field="email"
        header="Email"
        :sortable="true"
        frozen
        class="invitation-email"
      />
      <Column
        field="code"
        header="Invitation code"
        :sortable="false"
        class="invitation-code"
      />
      <Column
        field="status"
        header="Status"
        :sortable="true"
      >
        <template #body="slotProps">
          <span :class="'invitation-badge status-' + (isExpired(slotProps.data) ? 'EXPIRED': slotProps.data.status)">{{ isExpired(slotProps.data) ? 'EXPIRED': slotProps.data.status }}</span>
        </template>
      </Column>
      <Column
        field="expireDate"
        header="Expire date"
        :sortable="true"
        class="account-login"
      >
        <template #body="slotProps">
          <span>{{ formatLastLogin( slotProps.data.expireDate) }}</span>
        </template>
      </Column>
      <Column
        field="resend"
        header=""
        :sortable="false"
        class="justify-content-center"
      >
        <template #body="slotProps">
          <Button
            type="button"
            label="Resend invitation"
            class="p-button-sm p-button-secondary"
            :disabled="sendingEmail"
            @click="resendInvitation(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { FilterMatchMode } from 'primevue/api';
import { object, string } from 'yup';
import A2CAAuthorizedClient from '../../../api/a2caAuthorizedClient';

const sendInvitationSchema = object().shape({
  email: string().required().email(),
});

export default {
  components: { },
  inject: ['query'],
  props: {
  },
  data() {
    return {
      loading: false,
      updateKey: 0,
      invitations: null,
      sendingEmail: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },

      },
      values: {
        email: '',
      },
      errors: {
        email: '',
      },
    };
  },
  computed: {
    ...mapGetters(['royalties']),
  },
  async created() {
    this.loading = true;

    const client = new A2CAAuthorizedClient(this.query);
    const invitations = await client.getAccountInvitations();
    this.invitations = invitations;

    this.loading = false;
  },
  methods: {
    isExpired(invitation) {
      return invitation.status === 'ACTIVE' && invitation.dateHasExpired;
    },
    formatLastLogin(lastLogin) {
      return lastLogin ? dayjs(lastLogin).format('YYYY-MM-DD HH:mm:ss') : 'No data';
    },
    sendInvitationHandler() {
      const client = new A2CAAuthorizedClient(this.query);
      sendInvitationSchema
        .validate(this.values, { abortEarly: false })
        .then(async () => {
          this.errors = {};
          this.sendingEmail = true;
          try {
            const generatedInvitation = await client.generateAccountInvitation(this.values.email);
            if (generatedInvitation?.type) {
              this.$toast.add({
                severity: 'error',
                summary: 'Could not send invitation email',
                detail: 'The email already has an existing invitation',
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: 'success',
                summary: 'Invitation sent',
                detail: `An invitation has been sent to email ${this.values.email}`,
                life: 3000,
              });
              this.invitations = [generatedInvitation, ...this.invitations];
            }
          } catch (err) {
            this.$toast.add({
              severity: 'error',
              summary: 'Could not send invitation email',
              detail: err,
              life: 3000,
            });
          }
          this.sendingEmail = false;
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors[error.path] = error.message;
          });
          this.$toast.add({
            severity: 'error',
            summary: 'Could not send invitation email',
            detail: 'The email must be a valid email',
            life: 3000,
          });
        });
    },
    validate(field) {
      sendInvitationSchema
        .validateAt(field, this.values, { abortEarly: true })
        .then(() => {
          this.errors[field] = '';
        })
        .catch((err) => {
          this.errors[field] = err.message;
        });
    },
    async resendInvitation(invitation) {
      this.sendingEmail = true;
      const client = new A2CAAuthorizedClient(this.query);
      try {
        const resentInvitation = await client.resendAccountInvitation(invitation.email);
        if (resentInvitation?.type) {
          this.$toast.add({
            severity: 'error',
            summary: 'Could not resend invitation email',
            detail: 'Used invitations cannot be resent.',
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: 'success',
            summary: 'Invitation sent',
            detail: `An invitation has been resent to email ${this.values.email}`,
            life: 3000,
          });
          // TODO update resent invitation
          const resentIndex = this.invitations.findIndex((i) => i.id === resentInvitation.id);
          this.invitations[resentIndex] = resentInvitation;
        }
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Could not resend invitation email',
          detail: err,
          life: 3000,
        });
      }
      this.sendingEmail = false;
    },
  },
};
</script>
<style>
.invitation-email {
  min-width: 250px;
}
.invitation-code {
  min-width: 300px;
}
.account-login {
  min-width: 180px;
}
th, td {
  width: 120px;
}
</style>
<style scoped lang="scss">
@import "../../../assets/styles/dashboard/invitation.scss";
</style>
